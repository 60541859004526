export default {
  bio: "candidate/profile",
  update: "profile/update",
  marital: "setup/maritalStatus",
  titles: "setup/titles",
  sectors: "setup/sectors",
  countries: "setup/countries",
  cities: "setup/cities",
  counties: "setup/counties",
};
