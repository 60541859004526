import home from "@/modules/home/views/home";
import jobCard from "@/modules/home/components/jobCard";
import jobList from "@/modules/home/components/jobList";
import application from "@/modules/home/components/application";
import myApplications from "@/modules/home/components/my-applications";
// import myApplication from "@/modules/home/components/my-application";
// import jobQuestions from "@/modules/home/components/jobQuestions";
import attachments from "@/modules/profile/components/attachments/Attachments";
import checkAuth from "@/modules/home/components/checkAuth";
import profileHeader from "@/modules/home/components/profileHeader";
import AttachmentCard from "@/modules/profile/components/attachments/AttachmentCard";
import uploadCV from "@/modules/home/components/uploadCV";
import JobOfferDocument from "@/modules/home/components/JobOfferDocument";

export default [
  {
    path: "/",
    component: home,
    children: [
      {
        path: "",
        name: "Home",
        components: {
          view: jobList,
        },
      },
      {
        path: "profile",
        name: "Profile",
        components: {
          view: profileHeader,
        },
      },
      {
        path: "/job-card/:jobid/:vacancyid",
        name: "Job",
        components: {
          view: jobCard,
        },
      },
      {
        path: "/check/:jobid/:vacancyid",
        name: "Check",
        components: {
          view: checkAuth,
        },
      },

      {
        path: "/application/:code",
        name: "Application",
        components: {
          view: application,
        },
        children: [
          {
            path: "",
            redirect: "/application/:code/attachments",
          },
          {
            path: "attachments",
            name: "Attachments",
            component: attachments,
          },
          {
            path: "att-card/:line?",
            name: "AttachmentCard",
            component: AttachmentCard,
          },
        ],
      },
      {
        path: "/my-applications",
        name: "My Applications",
        components: {
          view: myApplications,
        },
      },

      // {
      //   path: "/job-questions/:code",
      //   name: "Questions",
      //   components: {
      //     view: jobQuestions,
      //   },
      // },
      {
        path: "uploadCv/:jobid/:vacancyid",
        name: "uploadCV",
        components: {
          view: jobList,
          action: uploadCV,
        },
      },
      {
        path: "job-offer/:code",
        name: "JobOffer",
        components: {
          view: JobOfferDocument,
        },
      },
    ],
  },
];
