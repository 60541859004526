<!-- eslint-disable no-extra-boolean-cast -->
<template>
  <v-form v-model="isValid" ref="contactForm">
    <v-row>
      <v-col md="6" sm="12">
        <v-text-field
          v-model="formData.eMail"
          :rules="rules.Field"
          ref="eMail"
          dense
          outlined
        >
          <template v-slot:label>
            <div>Email <span class="red--text">*</span></div>
          </template>
        </v-text-field>
      </v-col>
      <v-col md="6" sm="12">
        <vue-tel-input-vuetify
          required
          clearable
          placeholder="Enter Phone Number"
          label="Phone Number"
          persistent-placeholder
          autofocus
          :inputOptions="{
            showDialCode: true,
            tabindex: 0,
          }"
          outlined
          dense
          v-model="formData.phoneNo"
          defaultCountry="KE"
          :rules="rules.Field"
        ></vue-tel-input-vuetify>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { AuthService } from "../../../auth";

export default {
  name: "Contact",
  data() {
    return {
      type: "",
      edit: true,
      isValid: false,
      formData: {
        profileID: "",
        phoneNo: "",
        eMail: "",
        type: "Personal",
        countryCode: "KE",
      },
    };
  },

  computed: {
    rules() {
      return {
        Field: [(v) => !!v || "Field is required"],
      };
    },
    user() {
      return AuthService.user;
    },

    contact() {
      return this.$store.getters["Profile/contactGetters"]("contact");
    },
  },

  methods: {
    saveContact: function () {
      if (!this.isValid) {
        this.$refs.contactForm.validate();
      } else {
        delete this.formData.status;
        this.formData.profileID = this.user.profileID;
        this.formData.phoneNo = this.formData.phoneNo.replace(/\s/g, "");
        this.$store.dispatch("Profile/saveContact", this.formData);
      }
    },
    checkContact() {
      if (this.contact.length > 0) {
        return true;
      } else {
        return false;
      }
    },

    NumbersOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    editContact() {
      if (!this.isValid) {
        this.$refs.contactForm.validate();
      } else {
        this.formData.profileID = this.user.profileID;
        this.formData.phoneNo = this.formData.phoneNo.replace(/\s/g, "");
        delete this.lastModifiedTime;
        this.$store.dispatch("Profile/editContactDialog", this.formData);
      }
    },
    updateProfileContact() {
      if (this.formData.versionNo) {
        this.editContact();
      } else {
        this.saveContact();
      }
    },
    redirect() {
      this.$router.push({
        name: "Home",
      });
    },
  },

  watch: {
    contact: {
      handler: function () {
        if (this.contact.length > 0) {
          const contact = { ...this.contact[0] };
          Object.keys(this.formData).forEach((key) => {
            if (contact[key] === contact[key]) {
              this.formData[key] = contact[key];
            }
          });
          this.formData.versionNo = contact.versionNo;
          this.formData.entryNo = contact.entryNo;
        }
        if (this.contact.length === 0) {
          const phoneStr = this.formData.phoneNo;
          if (/^[A-Za-z0-9]*$/.test(phoneStr)) {
            this.formData.phoneNo = "";
            this.formData.eMail = this.user.email;
          } else {
            this.formData.phoneNo = this.user.phone;
            this.formData.eMail = this.user.email;
          }
        }
      },
    },
  },
};
</script>

<style scoped></style>
<!--  -->
