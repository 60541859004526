<template>
  <v-dialog persistent v-model="dialog" class="mt-n3" max-width="60%">
    <v-card v-if="!checkIfJobIsApplied()" ref="scrollingContainer">
      <v-card-title class="application--card-title">
        <span class="text-h5 font-weight-black">{{ job.fullTitle }} </span>
        <v-spacer />
        <v-btn
          color="deep-orange lighten-4 deep-orange--text text--darken-4 text-lowercase"
          elevation="0"
          @click="redirect()"
        >
          <v-icon class="mx-2">mdi-close</v-icon>
          Close
        </v-btn>
      </v-card-title>
      <v-divider />

      <v-scroll-x-transition>
        <v-card-text v-if="step === 0">
          <BioDialog class="mt-4" ref="bioDialog" />
        </v-card-text>
      </v-scroll-x-transition>

      <v-scroll-x-reverse-transition>
        <v-card-text v-if="step === 1">
          <AcademicCard ref="academicDialog" />
        </v-card-text>
      </v-scroll-x-reverse-transition>

      <v-scroll-x-reverse-transition>
        <v-card-text v-if="step === 2">
          <Experience ref="expDialog" />
        </v-card-text>
      </v-scroll-x-reverse-transition>

      <v-scroll-x-reverse-transition>
        <v-card-text v-if="step === 3">
          <Referee />
        </v-card-text>
      </v-scroll-x-reverse-transition>

      <v-scroll-x-reverse-transition>
        <v-card-text v-if="step === 4">
          <v-card-title v-if="attachments.length > 0">
            Mandatory Documents
          </v-card-title>
          <div v-if="attachments.length > 0">
            <v-list dense>
              <v-list-item-group
                v-model="selectedDocuments"
                color="primary"
                multiple
                class="mx-auto"
                active-class="selected--option green--text"
              >
                <v-list-item
                  v-for="(file, f) in attachments"
                  :key="f"
                  :value="file"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title class="text-body-2">
                        Use existing {{ file.documentCode }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        >Existing {{ file.documentCode }}:
                        {{ file.id }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-checkbox
                        :input-value="active"
                        color="green accent-4"
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-action>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            icon
                            v-on="on"
                            v-bind="attrs"
                            @click="downloadFile(base64, file.attachment)"
                          >
                            <v-icon class="mx-2">mdi-download</v-icon>
                          </v-btn>
                        </template>
                        <span>Download Existing {{ file.documentCode }}</span>
                      </v-tooltip>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>

          <v-card-title
            class="text-body-1 text-uppercase mb-n3 black--text font-weight-bold"
            v-if="attachments.length > 0"
          >
            <v-divider />
            <span class="mx-2">or</span>
            <v-divider />
          </v-card-title>

          <div>
            <v-card class="mx-auto" flat>
              <v-card-title>Required Documents</v-card-title>
              <v-divider class="mx-3 mt-n3 mb-2" />
              <v-list dense>
                <template v-for="(item, i) in mandatoryDocs">
                  <v-list-item
                    dense
                    :key="i"
                    class="my-n2"
                    v-if="notInSelected(item)"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="text-body-2">
                        Upload Your {{ item.description }}
                        <span class="red--text"> *</span>
                      </v-list-item-title>
                      <div>
                        <v-file-input
                          :ref="item.type"
                          accept="application/pdf"
                          prepend-icon="mdi-paperclip"
                          :placeholder="item.description"
                          dense
                          @change="uploadFile($event, item)"
                          outlined
                          show-size
                        />
                        <div class="file-size-info">
                          Maximum file size: 10MB
                        </div>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-card>
          </div>
        </v-card-text>
      </v-scroll-x-reverse-transition>

      <v-scroll-x-reverse-transition>
        <div v-if="step === 5">
          <reviewApplication
            :documents="attachments"
            :job="job"
            :profile="bio[0]"
            @backToStep="backToStep"
            :isDocuments="mandatoryDocs.length > 0"
          />
          <v-divider />
          <v-card class="mx-auto" flat>
            <v-card-title>What's your expected salary range?</v-card-title>
            <v-card-text>
              <v-form v-model="isSalaryValid" ref="otherDetailsFormForm">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="otherDetailsForm.minAskingSalary"
                      label="Minimum"
                      outlined
                      dense
                      type="number"
                      :rules="salaryRules"
                    >
                      <template v-slot:prepend-inner>
                        <span class="mt-1">KSH</span>
                      </template>
                      <template v-slot:label>
                        <span>Minimum</span>
                        <span class="red--text"> *</span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="otherDetailsForm.maxAskingSalary"
                      label="Maximum"
                      outlined
                      dense
                      type="number"
                      :rules="salaryRules"
                    >
                      <template v-slot:prepend-inner>
                        <span class="mt-1">KSH</span>
                      </template>
                      <template v-slot:label>
                        <span>Maximum</span>
                        <span class="red--text"> *</span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="mt-n4">
                    <v-menu
                      v-model="availableDateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="otherDetailsForm.availabilityToStart"
                          label="Available Date"
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                        >
                          <template v-slot:label>
                            <span>Available Date</span>
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="otherDetailsForm.availabilityToStart"
                        no-title
                        scrollable
                        :min="today"
                        @input="availableDateMenu = false"
                      />
                    </v-menu>
                  </v-col>
                  <!-- add notice period  -->
                  <v-col cols="12" md="6" class="mt-n4">
                    <v-text-field
                      v-model="otherDetailsForm.noticePeriod"
                      outlined
                      dense
                      placeholder="Enter your notice period"
                    >
                      <template v-slot:label>
                        <span>Enter your notice Period</span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="mt-n4">
                    <v-text-field
                      v-model="otherDetailsForm.workingScheme"
                      outlined
                      dense
                      placeholder="Enter your Preferred Working Environment"
                    >
                      <template v-slot:label>
                        <span>Preferred Working Environment</span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="mt-n4">
                    <v-switch
                      v-model="otherDetailsForm.formerEmployee"
                      label="Are you a former employee of this company?"
                      color="primary"
                      inset
                      class="mt-0"
                    ></v-switch>
                    <v-text-field
                    v-if="otherDetailsForm.formerEmployee"
                      v-model="otherDetailsForm.employeeNo"
                      outlined
                      dense
                      :rules="rules.Field"
                      placeholder="Enter employee number"
                    >
                      <template v-slot:label>
                        <span>Enter employee number</span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
          <v-divider v-if="job.vacancyQuestionnaire" />
          <jobQuestions ref="jobQuestions" />
        </div>
      </v-scroll-x-reverse-transition>

      <v-divider />
      <v-card-actions class="application--card-actions">
        <v-spacer></v-spacer>
        <v-btn v-if="step !== 0" class="mx-2" @click="step--">
          <v-icon class="mx-2">mdi-arrow-left</v-icon> Back
        </v-btn>
        <v-btn
          class="primary text-capitalize"
          v-if="step !== 5 && bio.length > 0"
          @click="step === 4 ? validateAttachments() : nextStep()"
        >
          Next <v-icon class="mx-2">mdi-arrow-right</v-icon></v-btn
        >
        <v-btn color="primary" v-if="step === 5" @click="Submit()">
          Submit <v-icon class="mx-2">mdi-arrow-right</v-icon></v-btn
        >
      </v-card-actions>
    </v-card>

    <v-card v-else>
      <v-col>
        <span class="red--text text-subtitle-2 font-italic caption pa-3"
          ><v-icon color="red" small>mdi-alert</v-icon>You have already applied
          to this position !</span
        >
      </v-col>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="redirect()"> Close </v-btn>
      </v-card-actions>
    </v-card>

    <Spinner />
  </v-dialog>
</template>

<script>
import { AuthService } from "../../auth";
import Spinner from "@/plugins/loader/views/Spinner";
import { helper } from "../../../utils";
import FileMixin from "@/mixin/FileMixin";
import BioDialog from "@/modules/profile/components/bio/BioDialog";
import reviewApplication from "./reviewApplication.vue";
import _ from "lodash";
import jobQuestions from "./jobQuestions.vue";
import AcademicCard from "../../profile/components/academic/AcademicList.vue";
import Experience from "../../profile/components/experience/Experience.vue";
import Referee from "../../profile/components/referees/Referees";
import fileType from "file-type";

export default {
  name: "uploadCV",
  components: {
    Spinner,
    BioDialog,
    reviewApplication,
    jobQuestions,
    AcademicCard,
    Experience,
    Referee,
  },
  mixins: [FileMixin],
  data: () => ({
    formData: {
      documentCode: "CV",
      relatedRecordId: "",
      attachment: "",
    },
    Doc: undefined,
    dialog: true,
    departments: [],
    uploadedDocuments: [],
    selectedDocuments: [],
    step: 0,
    otherDetailsForm: {
      minAskingSalary: 0,
      maxAskingSalary: 0,
      formerEmployee: false,
      availabilityToStart: "",
      noticePeriod: "",
      workingScheme: "",
      employeeNo:"",
    },
    availableDateMenu: false,
    isSalaryValid: false,
    today: new Date().toISOString().substr(0, 10),
  }),
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Profile/getBio");
      v.$store.dispatch("Profile/getContact");
      v.$store.dispatch("Profile/getAttachment", v.user.profileID);
      v.$store.dispatch("Home/getApplications", v.user.profileID);
      v.$store.dispatch("Home/getSingleJob", {
        jobid: v.$route.params.jobid,
        vacancyid: v.$route.params.vacancyid,
      });
      // v.$store.dispatch("Profile/getAcademic");
      // v.$store.dispatch("Profile/getExperience");
      // v.$store.dispatch("Profile/getReferee");
    });
  },
  mounted() {
    Event.$on("redirectToAllapplications", () => {
      this.$router.push({
        name: "My Applications",
      });
    });
    Event.$on("goNextStep", () => {
      if (this.mandatoryDocs.length > 0) {
        this.step++;
      } else {
        this.step = 4;
      }
    });
  },

  computed: {
    user() {
      return AuthService.user;
    },
    salaryRules() {
      return [(v) => !!v || "Salary is required"];
    },
    isUploadedOrSelected() {
      return this.uploadedDocuments.length === this.mandatoryDocs.length;
    },
    rules() {
      return {
        Field: [(v) => !!v || "Field is required"],
        attachment: [
          (v) => !!v || "Attachment is required",

          (v) =>
            (!!v && v.size < 10000000) || "Attachment should be less than 10MB",

          (v) =>
            (!!v && v.type.includes("application/pdf")) ||
            "Attachment must be a Pdf",
        ],
      };
    },
    bio() {
      return this.$store.getters["Profile/bioGetters"]("bio");
    },
    attachments() {
      return this.$store.getters["Profile/attachmentGetters"]("attachments");
    },
    latestCV() {
      return this.attachments
        ? this.attachments.find((d) => d.documentCode === "CV")
        : {};
    },
    applications: function () {
      return this.$store.getters["Home/homeGetters"]("applications");
    },
    job: function () {
      return this.$store.getters["Home/homeGetters"]("singleJob");
    },
    mandatoryDocs: function () {
      return this.job
        ? this.job.mandatoryDocs
          ? this.job.mandatoryDocs.filter(
              (d) =>
                !this.selectedDocuments.find((m) => m.documentCode === d.type)
            )
          : []
        : [];
    },
    qualifications() {
      return this.$store.getters["Profile/academicGetters"]("academic");
    },
    experience() {
      return this.$store.getters["Profile/experienceGetters"]("experience");
    },
    referees() {
      return this.$store.getters["Profile/refereeGetters"]("referees");
    },
  },

  methods: {
    Submit: function () {
      if (!this.isSalaryValid) {
        this.$refs.otherDetailsFormForm.validate();
        // navigate to salary range ref on the dialog
        this.$refs.otherDetailsFormForm.$el.scrollIntoView();
      } else {
        const data = {
          profileID: this.user.profileID,
          jobPosting: this.$route.params.vacancyid,
          maxAskingSalary: parseInt(this.otherDetailsForm.maxAskingSalary),
          minAskingSalary: parseInt(this.otherDetailsForm.minAskingSalary),
          formerEmployee: this.otherDetailsForm.formerEmployee,
          availabilityToStart: this.otherDetailsForm.availabilityToStart,
          workingScheme: this.otherDetailsForm.workingScheme,
          noticePeriod: this.otherDetailsForm.noticePeriod,
          employeeNo: this.otherDetailsForm.employeeNo,
        };
        if (this.job.vacancyQuestionnaire.length > 0) {
          this.$refs.jobQuestions.saveQuestions();
        }
        this.$store.dispatch("Home/createApplication", data);
      }
    },
    uploadFile: async function (value, item) {
      if (value) {
        const maxSize = 10 * 1024 * 1024; // 10MB in bytes
        if (value && value.size > maxSize) {
          alert("File size exceeds the maximum limit of 10MB.");
          this.$router.go(0);
        } else {
          item.attachment = await helper.toBase64(value);
          item.attachment = item.attachment.split(",")[1];
          item.relatedRecordId = this.user.profile.id;
          item.documentCode = item.type;
          // add item to uploaded documents if not already there else update
          if (!this.uploadedDocuments.find((d) => d.type === item.type)) {
            this.uploadedDocuments.push(item);
          } else {
            this.uploadedDocuments[
              _.findIndex(this.uploadedDocuments, { type: item.type })
            ] = item;
          }
        }
      } else {
        this.uploadedDocuments.splice(
          _.findIndex(this.uploadedDocuments, item),
          1
        );
      }
    },
    redirect() {
      this.$router.push({
        name: "Home",
      });
    },
    checkIfJobIsApplied: function () {
      if (
        this.applications.length !== 0 &&
        this.applications.find((d) => d.jobPosting === this.$route.params.vacancyid)
      ) {
        return true;
      } else {
        return false;
      }
    },
    notInSelected: function (item) {
      return !this.selectedDocuments.includes(item);
    },
    nextStep: function () {
      if (this.step === 0) {
        this.$refs.bioDialog.editProfile();
      } else {
        if (this.step === 1) {
          this.qualifications.length !== 0
            ? this.step++
            : Event.$emit(
                "ApiWarning",
                "Atleast one qualification is needeed to apply"
              );
        } else if (this.step === 2) {
          this.experience.length !== 0
            ? this.step++
            : Event.$emit(
                "ApiWarning",
                "Atleast one experience is needeed to apply"
              );
        } else if (this.step === 3) {
          this.referees.length !== 0
            ? this.step++
            : Event.$emit(
                "ApiWarning",
                "Atleast one referee is needeed to apply"
              );
        }
      }
    },
    validateAttachments: function () {
      if (this.selectedDocuments.length > 0) {
        this.step++;
      } else {
        if (this.isUploadedOrSelected) {
          const appData = {
            profileID: this.user.profileID,
            jobPosting: this.$route.params.vacancyid,
          };

          const userData = {
            profileID: this.user.profileID,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
          };

          const data = {
            attachData: [...this.uploadedDocuments],
            profileID: this.user.profileID,
            applData: appData,
            profileData: userData,
          };

          this.$store.dispatch(`Profile/saveMultipleAttachments`, data);
        } else {
          Event.$emit("ApiError", "Please upload all required documents");
        }
      }
    },
    backToStep: function (step) {
      this.step = step;
    },
    downloadFile: async function (attachment, filename = "") {
      const myBuffer = Buffer.from(attachment, "base64");
      const res = await fileType.fromBuffer(myBuffer);

      const path = `data:${res.mime})};base64,${attachment}`;
      let ext = res.mime.split("/");
      ext = ext.pop();

      switch (ext) {
        case "pdf":
          ext = "pdf";
          break;
        case "vnd.openxmlformats-officedocument.wordprocessingml.document":
          ext = "docx";
          break;
        case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          ext = "xlsx";
          break;
        case "jpeg":
        case "jpg":
          ext = "jpeg";
          break;
        case "png":
          ext = "png";
          break;
        default:
          ext = "pdf";
      }

      const filePath = filename || `attachment.${ext}`;

      const element = document.createElement("a");
      element.setAttribute("href", path);
      element.setAttribute("download", `${filePath}`);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
  },
};
</script>

<style>
.selected--option.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0px 3px 1px -2px rgba(3, 170, 86, 0.954),
    0px 2px 2px 0px rgba(3, 170, 86, 0.954),
    0px 1px 5px 0px rgba(3, 170, 86, 0.954);
  border-color: rgba(3, 170, 86, 0.954) !important;
}

.application--card-title {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
}

.application--card-actions {
  position: sticky;
  bottom: 0;
  z-index: 1;
  background-color: #fff;
}
.file-size-info {
  margin-top: -24px;
  margin-bottom: 16px;
  margin-left: 32px;
  color: #757575;
  font-size: 12px;
}
</style>
