<template>
  <v-container
    class="flex-column align-content-center"
    v-if="vacancyQuestionnaire"
  >
    <v-card flat>
      <v-card-text>
        <div v-for="(item, i) in questionnaireForm" :key="i">
          <span class="black--text"
            >{{ item.question }} <span class="red--text">*</span></span
          >
          <v-select
            dense
            outlined
            :items="item.questionnaireAnswers"
            v-model="item.answer"
            :rules="rules.required"
            item-value="lineNo"
            item-text="description"
          >
          </v-select>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import AuthService from "@/modules/auth/authService";

export default {
  name: "jobQuestions",
  data: function () {
    return {
      responses: {},
      answer: "",
      no: "",
      formData: {
        jobPosting: "",
        profileID: "",
        applicantResponses: [],
      },
      questionnaireForm: [],
      rules: {
        required: [(value) => !!value || "Required."],
      },
    };
  },

  mounted() {
    this.$store.dispatch("Home/getSingleJob", this.$route.params.code);
  },

  computed: {
    routeParams() {
      return this.$route.params.code;
    },

    user() {
      return AuthService.user;
    },
    job: function () {
      return this.$store.getters["Home/homeGetters"]("singleJob");
    },
    vacancyQuestionnaire() {
      return this.job ? this.job.vacancyQuestionnaire : [];
    },
    No: function () {
      return this.SingleApplication !== undefined
        ? this.SingleApplication.no
        : "";
    },
    bio() {
      return this.$store.getters["Profile/bioGetters"]("bio");
    },
  },

  methods: {
    saveQuestions() {
      const Number = this.No;
      this.questionnaireForm.forEach(function (element) {
        element.applicationNo = Number;
      });
      const data = {
        profileID: this.user.profileID,
        jobPosting: this.$route.params.code,
        questionnaireForm: this.questionnaireForm,
      };
      console.log(this.questionnaireForm);
      this.$store.dispatch("Home/saveQuestions", data);
    },
  },

  watch: {
    vacancyQuestionnaire: {
      handler: function (n) {
        if (n) {
          n.forEach((q) => {
            this.questionnaireForm.push({
              ...q,
              ...{
                answer: "",
                profileID: this.user.profileID,
                questionnaireCode: q.questionID,
                relatedLineNo: q.lineNo,
              },
            });
          });
        }
      },
    },
  },
};
</script>

<style scoped>
.v-input__append-outer {
  margin-top: 8px;
}
</style>
